<template>
  <button
    class="button"
    type="button"
    :class="{ secondary: secondary }"
    :disabled="disabled"
    @click="$emit('on-click')"
  >
    <span class="button__title" v-html="text"></span>
    <svg class="button__icon" v-if="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22"><path d="M21.1 6.7c-.6-1.3-1.4-2.5-2.4-3.5S16.5 1.4 15.2.8C13.9.3 12.5 0 11 0S8.1.3 6.7.9c-1.3.5-2.5 1.3-3.5 2.3-1 1-1.8 2.2-2.4 3.5C.3 8.1 0 9.5 0 11s.3 2.9.9 4.3c.6 1.3 1.4 2.5 2.4 3.5s2.2 1.8 3.5 2.4c1.3.5 2.7.8 4.2.8s2.9-.3 4.3-.9c1.3-.6 2.5-1.4 3.5-2.4s1.8-2.2 2.4-3.5c.5-1.2.8-2.7.8-4.2s-.3-2.9-.9-4.3zm-1.2 8.1c-.5 1.2-1.2 2.2-2.1 3.1-.9.9-1.9 1.6-3.1 2.1-1.2.5-2.4.8-3.7.8s-2.6-.2-3.7-.8c-1.2-.5-2.2-1.2-3.1-2.1-.9-.9-1.6-1.9-2.1-3.1-.5-1.2-.8-2.4-.8-3.7s.3-2.6.8-3.7c.5-1.2 1.2-2.2 2.1-3.1.9-.9 1.9-1.6 3.1-2.1 1.1-.6 2.4-.8 3.7-.8s2.6.3 3.7.8c1.2.5 2.2 1.2 3.1 2.1.9.9 1.6 1.9 2.1 3.1.5 1.2.8 2.4.8 3.7s-.3 2.5-.8 3.7z"/><path d="M11.9 6.7c-.2 0-.4.1-.5.2-.1.1-.2.3-.2.5s.1.4.2.5l2.5 2.5H6.2c-.2 0-.4.1-.5.2s-.2.2-.2.4.1.3.2.5c.1.1.3.2.5.2H14l-2.5 2.5c-.1.1-.2.3-.2.5s.1.4.2.5c.1.1.2.1.2.1.1 0 .2.1.3.1s.2 0 .3-.1c.1 0 .2-.1.2-.1l4.1-4.2-4.1-4.2c-.2-.1-.4-.1-.6-.1z"/></svg>
  </button>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  disabled = prop({
    type: Boolean,
    default: false,
  })
  secondary = prop({
    type: Boolean,
  })
  icon = prop({
    type: Boolean,
  })
  text = prop({
    type: String
  })
}

@Options({
  emits: ['on-click'],
})
export default class Button extends Vue.props(Props) {
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";

.button {
  height: 46px;
  font-weight: 400;
  background: $blue-2;
  border-radius: 50px;
  padding: 10px 26px;
  color: $white;
  border: 0;
  transition: all 0.2s linear;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  outline: none;

  &:disabled,
  &[disabled]{
    background: $gray-1;
    cursor: not-allowed;

    &:hover {
      background: $gray-1;
    }
  }

  &.secondary {
    background: $white;
    border: 2px solid $blue-2;
    color: $blue-2;

    @media only screen and (min-width: 960px) {
      &:hover {
        background: $blue-2;
        color: $white;
      }
    }
  }

  &__icon {
    width: 22px;
    margin-left: 10px;
    fill: $white;
  }

  @media only screen and (min-width: 960px) {
    &:hover {
      background: $black;
    }
  }
}
</style>
