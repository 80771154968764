<template>
  <div ref="dropdown" class="dropdown-container">
    <div class="dropdown selected" @click="isOpen = !isOpen" :class="{ alternative: isAlternative}">
      <div class="dropdown__label-wrapper">
        <span class="dropdown__label">{{ placeholder }}</span>

        <span
          class="dropdown__chosen-option"
          v-html="isSelectedOptionString"
        ></span>
      </div>
      <svg
        v-if="!isAlternative"
        :class="{ open: isOpen }"
        class="dropdown__icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 11.7 14.6"
      >
        <path
          d="M11.4 7.9c-.2-.2-.4-.3-.6-.3-.2 0-.5.1-.6.3l-3.3 3.3V.9c-.1-.5-.5-.9-1-.9h-.1c-.2 0-.4.1-.6.3-.2.1-.3.4-.3.6v10.4L1.6 7.9c-.4-.3-.9-.3-1.3 0-.1.1-.2.2-.2.3-.1.2-.1.5 0 .7 0 .1.1.2.2.3l5.6 5.4 5.6-5.4c.2-.3.3-.9-.1-1.3z"
          fill="#0a4073"
        />
      </svg>
      <svg 
        v-if="isAlternative"
        class="dropdown__icon alternative" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 18.1 18">
        <path d="M17.3 12.5c-.5 1.1-1.1 2.1-1.9 2.9s-1.8 1.5-2.9 1.9c-1.1.5-2.3.7-3.5.7s-2.4-.2-3.5-.7c-1.1-.5-2.1-1.1-2.9-1.9S1.1 13.6.7 12.5C.2 11.4 0 10.2 0 9s.2-2.4.7-3.5c.5-1.1 1.1-2.1 1.9-2.9S4.4 1.1 5.5.7C6.6.2 7.8 0 9 0s2.4.2 3.5.7c1.1.5 2.1 1.1 2.9 1.9s1.5 1.8 1.9 2.9c.5 1.1.7 2.3.7 3.5s-.2 2.4-.7 3.5zm-15.6-.4c.4 1 1 1.8 1.7 2.5s1.6 1.3 2.5 1.7c.9.4 2 .6 3.1.6s2.1-.2 3.1-.6c1-.4 1.8-1 2.5-1.7s1.3-1.5 1.7-2.5c.4-.9.6-2 .6-3.1s-.2-2.1-.6-3.1c-.4-1-1-1.8-1.7-2.5S13 2.2 12.1 1.7c-.9-.4-2-.6-3.1-.6s-2.1.2-3.1.6c-1 .4-1.8 1-2.5 1.7S2.1 5 1.7 5.9c-.4 1-.6 2-.6 3.1s.2 2.1.6 3.1zm10.7-2.7c.1.1.2.3.2.4s-.1.3-.2.4L9 13.5l-3.4-3.3c0-.1-.1-.1-.1-.2v-.2-.2c0-.1.1-.1.1-.2.1-.2.2-.2.4-.2s.3.1.4.2l2.1 2.1V5.1c0-.2.1-.3.2-.4s.1-.2.3-.2c.2 0 .3.1.4.2s.2.2.2.4v6.3l2-2c.1-.1.2-.2.4-.2s.3 0 .4.2z" fill="#0a4073"/>
      </svg>

    </div>
    <ul v-if="isOpen" class="dropdown__options">
      <li v-for="(option, index) in options" :key="index">
        <Checkbox
          v-if="isCheckbox"
          :text="option.text"
          :value="option.isSelected"
          @on-click="onOption(option)"
        />
        <span v-else @click="onOption(option)" v-html="option.text"></span>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { IOption } from '@/types';
import { Options, prop, Vue } from 'vue-class-component';
import Checkbox from './Checkbox.vue';

class Props {
  options = prop({
    type: Object as () => IOption[],
    required: true,
  })

  isAlternative = prop({
    type: Boolean,
  })

  placeholder = prop({
    type: String,
    required: true,
  })

  isCheckbox = prop({
    type: Boolean,
    required: true,
  })
}

@Options({
  emits: ['select'],
  components: {
    Checkbox
  },
})
export default class Dropdown extends Vue.props(Props) {
  isOpen = false

  $refs!: {
    dropdown: HTMLElement,
  }

  get isSelectedOptions(): IOption[] {
    return this.options.filter((option) => option.isSelected)
  }

  get isSelectedOptionString(): string {
    return this.isSelectedOptions.map((o) => o.text).join(', ')
  }

  mounted() {
    document.addEventListener('click', this.bindClick)
  }

  unmounted() {
    document.removeEventListener('click', this.bindClick)
  }

  bindClick(event: Event) {
    const $tooltip: HTMLElement | undefined = this.$refs.dropdown

    if ($tooltip === undefined || this.isOpen === false) { return }

    if ($tooltip.contains((event.target as HTMLElement)) === false) {
      this.isOpen = false
    }
  }

  onOption(option: IOption) {
    if (this.isCheckbox === false) {
      this.isOpen = false
    }
    this.$emit('select', option.id)
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";
.dropdown-container {
  position: relative;
}
.dropdown {
  border: 2px solid $gray-1;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  cursor: pointer;
  transition: all 0.2s linear;

  &.alternative {
    border: 0;
    padding: 0;
    height: 39px;
    border-bottom: 2px solid $gray-1;

    .dropdown__label {
      margin-bottom: 8px;
    }

    &:hover {
      border: 0;
      border-bottom: 2px solid $gray-1;
    }
  }

  @media only screen and (min-width: 960px) {
    &:hover {
      border: 2px solid $gray-2;
    }
  }

  &__label {
    font-size: 14px;
    color: $gray-2;
    font-weight: 500;
    
  }

  &__icon {
    width: 14px;
    transition: all 0.1s ease-in-out;
    &.alternative {
      width: 18px;
    }
    &.open {
      transform: rotate(180deg);
    }
  }

  &.selected {
    .dropdown__label-wrapper {
      display: flex;
      flex-direction: column;

      .dropdown__label {
        font-size: 12px;
        margin-bottom: 2px;
      }
      .dropdown__chosen-option {
        font-size: 14px;
        font-weight: 500;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 270px;
      }
    }

    &.alternative {
      .dropdown__label {
        margin-bottom: 8px;
      }
    }
  }

  &__options {
    border: 2px solid $gray-1;
    border-top: 0;
    list-style: none;
    padding: 16px 20px;
    max-height: 200px;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    background: $white;
    box-sizing: border-box;
    z-index: 1;

    li {
      margin-bottom: 13px;
      color: $gray-2;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        color: $black;
      }
    }
  }
}
</style>
