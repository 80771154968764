<template>
  <ul class="tiles">
    <li v-for="(tile, index) in options" :key="index">
      <Tile
        :id="tile.id"
        :text="tile.text"
        :isSelected="tile.isSelected"
        :iconKeys="tile.iconKeys"
        @click="onOption(tile)"
      />
    </li>
  </ul>
</template>

<script lang="ts">
import { StepsModule } from '@/composables/use-steps';
import { STEP_SELECT_OPTION_NOT_INSTANT } from '@/config/steps';
import { IOption, IStepSelect } from '@/types';
import { Options, Vue, prop } from 'vue-class-component';
import Tile from './Tile.vue'

class Props {
  options = prop({
    type: Object as () => IOption[],
    required: true,
  })

  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })
}

@Options({
  components: {
    Tile,
  }
})
export default class Tiles extends Vue.props(Props) {
  onOption(tile: IOption) {
    this.stepsModule.toggleOption(tile.id, this.stepsModule.state.stepCurrent)

    const isOptionInstant = !STEP_SELECT_OPTION_NOT_INSTANT.includes((tile.id as IStepSelect))

    if (isOptionInstant && this.stepsModule.state.stepCurrent.isMultiple === false) {
      this.stepsModule.next()
    }
  }
}
</script>

<style lang="scss" scoped>
ul.tiles {
  margin: 0 auto;
  padding: 0;
  list-style: none;
  display: grid;
  width: fit-content;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 30px 30px;
  grid-template-areas:
    ". ."
    ". .";

  li {
    display: flex;
    justify-content: center;
  }
  @media only screen and (min-width: 960px) {
    gap: 20px 20px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      ". . ."
      ". . .";
  }
}
</style>
