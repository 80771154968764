<template>
  <div class="step">
    <Overlay
      v-if="stepsModule.state.isOverlayActive && isOverlayClosed === false"
      @on-close="isOverlayClosed = true"
      :stepsModule="stepsModule"
    />

    <component :is="layout" :stepsModule="stepsModule">
      <transition name="fade" mode="out-in">
        <component
          :key="stepsModule.state.stepCurrentIndex"
          :is="template"
          v-if="template"
          :stepsModule="stepsModule"
          :formModule="formModule"
          :options="optionsMapped"
        ></component>
      </transition>
    </component>
  </div>
</template>

<script lang="ts">
import { IHomeStyle, IOption, IStep, IStepLayout, IStepTemplate, IStepType } from '@/types';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import { TranslationHandler } from '../mixins/translation-handler'
import { OptionsHandler } from '../mixins/options-handler'
import Overlay from './Overlay.vue'
// Layouts
import StepHome from './StepHome.vue'
import StepPrefooterHome from './StepPrefooterHome.vue'
import StepSubpageHome from './StepSubpageHome.vue'
import StepEmpty from './StepEmpty.vue'
// Templates
import Tiles from './Tiles.vue'
import DropdownSelect from './DropdownSelect.vue'
import Contact from './Contact.vue'
import Overview from './Overview.vue'
import Calendar from './Calendar.vue'
import ThankYou from './ThankYou.vue'
import { StepsModule } from '@/composables/use-steps';
import { TrackingModule, useTracking } from '@/composables/use-tracking'
import { FormModule, useForm } from '@/composables/use-form';

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })

  appId = prop({
    type: String,
    required: true,
  })
}

@Options({
  watch: {
    'stepsModule.state.stepCurrent'(step: IStep) {
      this.trackingModule.push(step.type, this.stepsModule.state.routeNumber)

      this.stepsModule.state.isIndexChangeLocked = true

      setTimeout(() => {
        this.stepsModule.state.isIndexChangeLocked = false
      }, 350) // timeout like the transition

      setTimeout(() => {
        this.onOpen()
      }, 400)

      window.scrollTo(0, 0)
    },
    'stepsModule.state.selected.ELECTRICITY'() {
      this.isOverlayClosed = false
    },
  },
  components: {
    Overlay,
  }
})
export default class Step extends mixins(Vue.props(Props), TranslationHandler, OptionsHandler) {
  isOverlayClosed: boolean = false

  trackingModule: TrackingModule = useTracking()

  formModule: FormModule = useForm(this.formUrls)

  get formUrls(): string[] {
    if (process.env.NODE_ENV === 'development') {
      return ['https://hooks.zapier.com/hooks/catch/8890392/ol4w4xc/']
    }

    if (this.stepsModule.state.stepCurrent.type === IStepType.CONTACT_SHORT) {
      return ['https://hook.integromat.com/c5c2drueqk0onb9keet4ondv6uwgkquw', 'https://hooks.zapier.com/hooks/catch/5304661/olk1ov0/']
    }

    return ['https://hook.integromat.com/2m0u7a41d2e94lddwbrmf773dt8on6lp', 'https://hooks.zapier.com/hooks/catch/5304661/ol44wpd/']
  }

  get layout() {
    switch (this.stepsModule.state.stepCurrent.layout) {
      case IStepLayout.HOME: {
        switch (this.stepsModule.state.homeStyle) {
          case IHomeStyle.PRE_FOOTER: {
            return StepPrefooterHome
          }
          case IHomeStyle.SUB_PAGE: {
            return StepSubpageHome
          }
          default: {
            return StepHome
          }
        }
      }
      default: {
        return StepEmpty
      }
    }
  }

  get template() {
    switch (this.stepsModule.state.stepCurrent.template) {
      case IStepTemplate.TILES: {
        return Tiles
      }
      case IStepTemplate.DROPDOWN_SELECT: {
        return DropdownSelect
      }
      case (IStepTemplate.CONTACT): {
        return Contact
      }
      case IStepTemplate.OVERVIEW: {
        return Overview
      }
      case IStepTemplate.CALENDAR: {
        return Calendar
      }
      case IStepTemplate.THANK_YOU: {
        return ThankYou
      }
      default: {
        return null
      }
    }
  }

  get optionsMapped(): IOption[] {
    return this.$optionsGet(
      this.stepsModule.state.stepCurrent,
      this.stepsModule.state.selected,
      this.$t,
    )
  }

  onOpen() {
    // This functions makes sure that all the content under the config is hidden after home step
    const $app = document.getElementById(this.appId)

    if ($app && this.stepsModule.state.stepCurrentIndex !== 0) {
      $app.classList.add('klix-app-fixed')

      const height: number = $app.clientHeight
      const headerHeight: number = 72 // klix-nav in wordprress
      const offsetBottom: number = 100

      document.body.style.height = `${height + headerHeight + offsetBottom}px`
      document.body.style.overflow = 'hidden'

      return
    }
    if ($app) {
      $app.classList.remove('klix-app-fixed')
    }
    document.body.style.height = 'auto'
    document.body.style.overflow = 'auto'
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
