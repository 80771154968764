import { IStepsSelected } from '@/types'
import { reactive } from 'vue'

export async function asyncForEach(array: any[], callback: Function) {
  for (let index = 0; index < array.length; index += 1) {
    /* eslint no-await-in-loop: 'off' */
    await callback(array[index], index, array)
  }
}


interface State {
  contactDetails: { [k: string]: (string | boolean) }
  selected: IStepsSelected
}

export interface FormModule {
  state: State
  setContactDetails: (contactDetails: { [k: string]: (string | boolean) }) => void
  send: (contactDetails: { [k: string]: (string | boolean) }, selected: IStepsSelected) => Promise<boolean>
}

export function useForm(urls: string[]): FormModule {
  const state: State = reactive({
    contactDetails: {},
    selected: {},
  })

  function setContactDetails(contactDetails: { [k: string]: (string | boolean) }) {
    state.contactDetails = {
      ...state.contactDetails,
      ...contactDetails
    }
  }

  async function send(contactDetails: { [k: string]: (string | boolean) }, selected: IStepsSelected) {
    setContactDetails(contactDetails)
    state.selected = selected

    const body = {
      ...state.contactDetails,
      ...state.selected,
    }

    let isSuccess: boolean = false

    await asyncForEach(urls, async (url: string) => {
      const res = await window.fetch(url, {
        method: 'POST',
        body: JSON.stringify(body)
      })

      isSuccess = res.status === 200
    })

    return isSuccess
  }

  return {
    state,
    setContactDetails,
    send,
  }
}
