
import { StepsModule } from '@/composables/use-steps';
import { FormModule } from '@/composables/use-form';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import Textarea from './Textarea.vue'
import Input from './Input.vue'
import Switch from './Switch.vue'
import Dropdown from './Dropdown.vue'
import Button from './Button.vue'
import { IOption, IStepsSelected } from '@/types';
import { TranslationHandler } from '@/mixins/translation-handler';

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })
  formModule = prop({
    type: Object as () => FormModule,
    required: true,
  })
}

@Options({
  components: {
    Input,
    Switch,
    Dropdown,
    Textarea,
    Button,
  }
})
export default class Contact extends mixins(TranslationHandler, Vue.props(Props)) {
  get salutations(): IOption[] {
    return [
      {
        id: '',
        text: '',
        isSelected: this.form.salutation === '',
      },
      {
        id: 'Herr',
        text: this.$t(['form', 'salutationMan']),
        isSelected: this.form.salutation === 'Herr',
      },
      {
        id: 'Frau',
        text: this.$t(['form', 'salutationWoman']),
        isSelected: this.form.salutation === 'Frau',
      },
      {
        id: 'keine Angabe',
        text: this.$t(['form', 'salutationNone']),
        isSelected: this.form.salutation === 'keine Angabe',
      }
    ]
  }

  isErrorShown: boolean = false

  form: { [k: string]: (string | boolean) } = {
    salutation: '',
    firstName: '',
    lastName: '',
    firm: '',
    tel: '',
    email: '',
    message: '',
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  }

  formRequired: string[] = [
    'firstName',
    'lastName',
    'firm',
    'tel',
    'email',
    'message',
    'checkbox1',
    'checkbox3',
  ]

  formEmail: string[] = ['email']
  formTel: string[] = ['tel']

  getIsFieldError(type: string, val: string): boolean {
    const isRequiredError = this.formRequired.includes(type) && !this.getIsValidRequired(val)
    const isEmailError = this.formEmail.includes(type) && !this.getIsValidEmail(val)
    const isTelError = this.formTel.includes(type) && !this.getIsValidTel(val)

    return this.isErrorShown && (isRequiredError || isEmailError || isTelError)
  }

  getIsValidRequired(val: string | boolean): boolean {
    return (val !== '' && val !== false)
  }

  getIsValidEmail(val: string): boolean {
    return (/\S+@\S+\.\S+/).test(val) && !(/\s/g).test(val)
  }

  getIsValidTel(val: string): boolean {
    return (/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g).test(val)
  }

  get formIsValid(): boolean {
    const isRequired = !this.formRequired.some(entry => !this.getIsValidRequired(this.form[entry]))
    const isEmail = !this.formEmail.some(entry => !this.getIsValidEmail(this.form[entry] as string))
    const isTel = !this.formTel.some(entry => !this.getIsValidTel((this.form[entry] as string)))

    return isRequired && isEmail && isTel
  }

  created() {
    this.form.message = this.$t(['form', 'messageDefault'])
  }

  setFormValue(key: string, value: string | boolean) {
    this.form[key] = value
  }

  get selectedTranslated(): {} {
    const selected: IStepsSelected = {}
    Object
      .keys(this.stepsModule.state.selected)
      .forEach((key) => (
        selected[key] = this.stepsModule.state.selected[key]
          .map((selectedKey) => {
            const value = this.$t([key, 'options', selectedKey])

            return value.replace(/<\/?[^>]+(>|$)/g, '')
          })
      ))

    return selected
  }

  async submit() {
    this.isErrorShown = false

    if (this.formIsValid === false) {
      this.isErrorShown = true
      return
    }

    const isSuccess = await this.formModule.send(this.form, this.selectedTranslated)

    if (isSuccess === true) {
      this.stepsModule.next()
      return
    }

    alert('Error')
  }
}
