<template>
  <div class="steps">
    <div class="steps__close" @click="stepsModule.backHome">×</div>
    <div class="steps__header">
      <h2 class="steps__header__title">
        {{ $t(["general", "title"]) }}
      </h2>
      <ProgressBar
        v-if="isProgressBarShown"
        :progress="stepsModule.state.stepProgressPercentage"
      />
    </div>
    <div class="steps__stage">
      <div class="wrapper" :style="styleWrapper">
        <div class="steps__stage__title">
          {{ $t([stepsModule.state.stepCurrent.type, "headline"]) }}

          <Tooltip
            :stepsModule="stepsModule"
            v-if="stepsModule.state.stepCurrent.isTooltip"
          />
        </div>
        <p class="steps__stage__hint" v-if="hint">
          {{ hint }}
        </p>
        <slot></slot>
      </div>
    </div>
    <div class="steps__control-strip" v-if="isNavigationShown">
      <Button
        id="button-back"
        :secondary="true"
        :text="$t(['general', 'buttonBack'])"
        @on-click="stepsModule.back"
        :disabled="!stepsModule.state.isBackAllowed"
      />
      <p>
        {{ $t(["general", "contact"]) }}
        <span @click="stepsModule.nextContact">{{
          $t(["general", "contactClick"])
        }}</span>
      </p>
      <Button
        id="button-next"
        @on-click="stepsModule.next"
        :disabled="!stepsModule.state.isNextAllowed"
        :icon="true"
        :text="$t(['general', 'buttonNextStep'])"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { StepsModule } from '@/composables/use-steps';
import { IStepTemplate } from '@/types';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import { TranslationHandler } from '../mixins/translation-handler'
import Button from './Button.vue'
import ProgressBar from './ProgressBar.vue'
import Tooltip from './Tooltip.vue'

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })
}

@Options({
  emits: ['on-next', 'on-back'],
  components: {
    Button,
    ProgressBar,
    Tooltip,
  },
})
export default class StepEmpty extends mixins(Vue.props(Props), TranslationHandler) {
  isTooltipShown = false

  get styleWrapper() {
    return {
      'max-width': this.stepsModule.state.stepCurrent.template === IStepTemplate.CALENDAR ? '100%' : '536px',
    }
  }

  get isNavigationShown(): boolean {
    return [IStepTemplate.TILES, IStepTemplate.DROPDOWN_SELECT, IStepTemplate.OVERVIEW, IStepTemplate.CALENDAR]
      .includes(this.stepsModule.state.stepCurrent.template)
  }

  get isProgressBarShown(): boolean {
    return [IStepTemplate.THANK_YOU]
      .includes(this.stepsModule.state.stepCurrent.template) === false
  }

  get hint(): string | null {
    if (this.stepsModule.state.stepCurrent.options === undefined && this.stepsModule.state.stepCurrent.optionsMap === undefined) {
      return null
    }

    return this.stepsModule.state.stepCurrent.isMultiple
      ? this.$t(['general', 'multipleChoice'])
      : this.$t(['general', 'singleChoice'])

  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";

.steps {
  width: 100%;
  max-width: 812px;
  margin: 73px auto 0;
  padding: 20px;
  box-sizing: border-box;

  @media only screen and (min-width: 960px) {
    padding: 0;
    padding-top: 60px;
  }

  &__close {
    position: absolute;
    font-size: 30px;
    cursor: pointer;
    top: 20px;
    right: 20px;

    @media only screen and (min-width: 960px) {
      top: 120px;
      right: 40px;
    }
  }

  &__header {
    padding-bottom: $spacing-4;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    @media only screen and (min-width: 960px) {
      margin-top: 0;
    }

    &__title {
      font-weight: 400;
      font-size: 26px;
      line-height: 30px;
      letter-spacing: 2px;
      text-align: center;
      margin: 0;
      margin-bottom: $spacing-3;
      max-width: 500px;
      width: 100%;
      position: relative;

      @media only screen and (min-width: 960px) {
        font-size: 32px;
        line-height: 35px;
      }
    }
  }

  &__stage {
    display: flex;
    margin-bottom: $spacing-2;
    margin-bottom: $spacing-4;
    flex-direction: column;
    align-items: center;

    .wrapper {
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @media only screen and (min-width: 960px) {
      margin-bottom: $spacing-2;
      border-radius: 10px;
      box-shadow: 0px 3px 14px 0 rgb(0 0 0 / 0.16);
      padding: $spacing-4 0 $spacing-5 0;
      min-height: 528px;
      box-sizing: border-box;
    }

    &__title {
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 2px;
      text-align: center;
      margin: 0;
      margin-bottom: $spacing-1;
      position: relative;

      @media only screen and (min-width: 960px) {
        font-size: 26px;
        line-height: 32px;
      }
    }

    &__hint {
      font-size: 14px;
      color: $gray-2;
      margin-bottom: $spacing-2;
      text-align: center;
    }
  }

  &__control-strip {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 960px) {
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }

    .button {
      margin-bottom: $spacing-2;

      &:first-child {
        order: 2;
      }

      @media only screen and (min-width: 960px) {
        margin-bottom: 0;

        &:first-child {
          order: inherit;
        }
      }
    }

    p {
      font-size: 14px;
      color: $gray-2;
      order: 3;
      text-align: center;

      @media only screen and (min-width: 960px) {
        order: inherit;
        text-align: inherit;
      }

      span {
        text-decoration: none;
        border-bottom: 1px solid $gray-2;
        color: inherit;
        cursor: pointer;
      }
    }
  }
}
</style>
