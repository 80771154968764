<template>
  <div class="progress-bar">
    <div class="progress" :style="style"></div>
  </div>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  progress = prop({
    type: Number
  })
}

@Options({
  
})
export default class ProgressBar extends Vue.props(Props) {
  get style() {
    return {
      width: `${this.progress}%`
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";

.progress-bar {
  width: 100%;
  height: 10px;
  border-radius: 10px;
  background: #E1E7ED;
  position: relative;
  overflow: hidden;

  .progress {
    background: $brown;
    position: absolute;
    top:0;
    left: 0;
    height: 100%;
  }
}

</style>
