<template>
  <div :id="appId" class="config-app">
    <Step :stepsModule="stepsModule" :appId="appId" />
  </div>
</template>

<script lang="ts">
import { Options, Vue, setup, prop } from 'vue-class-component'
import Step from './components/Step.vue'
import { useSteps } from './composables/use-steps'
import { TrackingModule, useTracking } from './composables/use-tracking'
import { IHomeStyle, IMap, IPreselectStepRoute, IStepType } from './types'

class Props {
  imageMap = prop({
    type: Object as () => IMap,
    required: true
  })
  fontMap = prop({
    type: Object as () => IMap,
    required: true
  })
  textMap = prop({
    type: Object as () => IMap,
    required: true
  })
  preselectMap = prop({
    type: Object as () => IMap,
    required: true
  })
  preselectStepsRoute = prop({
    type: String as () => IPreselectStepRoute,
    default: IPreselectStepRoute.PRODUCT
  })
  homeStyle = prop({
    type: String as () => IHomeStyle,
    default: IHomeStyle.HOME_PAGE
  })
}

@Options({
  components: {
    Step,
  },
})
export default class App extends Vue.props(Props) {
  trackingModule: TrackingModule = useTracking()
  stepsModule = setup(() => useSteps(
    this.imageMap,
    this.homeStyle,
    this.textMap,
    this.preselectMap,
    this.preselectStepsRoute
  ))

  get appId(): string {
    return this.homeStyle === 'pre-footer' ? 'klix-config-footer' : 'klix-config'
  }

  mounted() {
    const $style = document.createElement('style')

    $style.appendChild(document.createTextNode(`
      @font-face {
        font-family: "Lavazza";
        src: url("${this.fontMap['regular']}") format("truetype");
        font-style: normal;
        font-weight: 400;
      }

      @font-face {
        font-family: "Lavazza";
        src: url("${this.fontMap['bold']}") format("truetype");
        font-style: normal;
        font-weight: 500;
      }

      @font-face {
        font-family: "Lavazza";
        src: url("${this.fontMap['bolder']}") format("truetype");
        font-style: normal;
        font-weight: 600;
      }
    `));

    document.head.appendChild($style);
    this.trackingModule.push(IStepType.PRODUCT_TYPE)
  }
}
</script>

<style lang="scss">
.config-app {
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    border: 0;
    font-size: 100%;
    /* font: inherit; */
    vertical-align: baseline;
    margin: 0;
    padding: 0;
  }
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
}
</style>

<style lang="scss" scoped>
#klix-config-footer {
  background: #fff;
  position: relative;

  font-family: Lavazza, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#klix-config {
  padding-bottom: 50px;
  background: #fff;
  position: relative;

  font-family: Lavazza, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#klix-config.klix-app-fixed,
#klix-config-footer.klix-app-fixed {
  position: fixed;
  z-index: 99;
  width: 100%;
  padding-bottom: 100px;
  top: 0;
  left: 0;
  bottom: 10px;
  overflow-y: scroll;
}
</style>