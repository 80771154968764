<template>
  <div class="contact">
    <div class="input-wrapper">
      <Dropdown
        :options="salutations"
        :placeholder="this.$t(['form', 'salutation'])"
        @select="setFormValue('salutation', $event)"
      />
      <Input
        :placeholder="this.$t(['form', 'firstName'])"
        @on-change="setFormValue('firstName', $event)"
        :value="form.firstName"
        :error="getIsFieldError('firstName', form.firstName)"
      />
      <Input
        :placeholder="this.$t(['form', 'lastName'])"
        @on-change="setFormValue('lastName', $event)"
        :value="form.lastName"
        :error="getIsFieldError('lastName', form.lastName)"
      />
      <Input
        :placeholder="this.$t(['form', 'firm'])"
        @on-change="setFormValue('firm', $event)"
        :value="form.firm"
        :error="getIsFieldError('firm', form.firm)"
      />
      <Input
        :placeholder="this.$t(['form', 'email'])"
        type="email"
        @on-change="setFormValue('email', $event)"
        :value="form.email"
        :error="getIsFieldError('email', form.email)"
      />
      <Input
        :placeholder="this.$t(['form', 'tel'])"
        type="tel"
        @on-change="setFormValue('tel', $event)"
        :value="form.tel"
        :error="getIsFieldError('tel', form.tel)"
      />
    </div>
    <Textarea
      :placeholder="this.$t(['form', 'message'])"
      @on-change="setFormValue('message', $event)"
      :value="form.message"
      :error="getIsFieldError('message', form.message)"
    />
    <Switch
      :text="this.$t(['form', 'checkbox1'])"
      @on-change="setFormValue('checkbox1', $event)"
      :value="form.checkbox1"
    />
    <Switch
      :text="this.$t(['form', 'checkbox2'])"
      @on-change="setFormValue('checkbox2', $event)"
      :value="form.checkbox2"
    />
    <Switch
      :text="this.$t(['form', 'checkbox3'])"
      @on-change="setFormValue('checkbox3', $event)"
      :value="form.checkbox3"
    />
    <div @click="submit">
      <Button
        id="button-next"
        :text="$t(['form', 'button'])"
        :disabled="!formIsValid"
        :icon="true"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { StepsModule } from '@/composables/use-steps';
import { FormModule } from '@/composables/use-form';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import Textarea from './Textarea.vue'
import Input from './Input.vue'
import Switch from './Switch.vue'
import Dropdown from './Dropdown.vue'
import Button from './Button.vue'
import { IOption, IStepsSelected } from '@/types';
import { TranslationHandler } from '@/mixins/translation-handler';

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })
  formModule = prop({
    type: Object as () => FormModule,
    required: true,
  })
}

@Options({
  components: {
    Input,
    Switch,
    Dropdown,
    Textarea,
    Button,
  }
})
export default class Contact extends mixins(TranslationHandler, Vue.props(Props)) {
  get salutations(): IOption[] {
    return [
      {
        id: '',
        text: '',
        isSelected: this.form.salutation === '',
      },
      {
        id: 'Herr',
        text: this.$t(['form', 'salutationMan']),
        isSelected: this.form.salutation === 'Herr',
      },
      {
        id: 'Frau',
        text: this.$t(['form', 'salutationWoman']),
        isSelected: this.form.salutation === 'Frau',
      },
      {
        id: 'keine Angabe',
        text: this.$t(['form', 'salutationNone']),
        isSelected: this.form.salutation === 'keine Angabe',
      }
    ]
  }

  isErrorShown: boolean = false

  form: { [k: string]: (string | boolean) } = {
    salutation: '',
    firstName: '',
    lastName: '',
    firm: '',
    tel: '',
    email: '',
    message: '',
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  }

  formRequired: string[] = [
    'firstName',
    'lastName',
    'firm',
    'tel',
    'email',
    'message',
    'checkbox1',
    'checkbox3',
  ]

  formEmail: string[] = ['email']
  formTel: string[] = ['tel']

  getIsFieldError(type: string, val: string): boolean {
    const isRequiredError = this.formRequired.includes(type) && !this.getIsValidRequired(val)
    const isEmailError = this.formEmail.includes(type) && !this.getIsValidEmail(val)
    const isTelError = this.formTel.includes(type) && !this.getIsValidTel(val)

    return this.isErrorShown && (isRequiredError || isEmailError || isTelError)
  }

  getIsValidRequired(val: string | boolean): boolean {
    return (val !== '' && val !== false)
  }

  getIsValidEmail(val: string): boolean {
    return (/\S+@\S+\.\S+/).test(val) && !(/\s/g).test(val)
  }

  getIsValidTel(val: string): boolean {
    return (/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g).test(val)
  }

  get formIsValid(): boolean {
    const isRequired = !this.formRequired.some(entry => !this.getIsValidRequired(this.form[entry]))
    const isEmail = !this.formEmail.some(entry => !this.getIsValidEmail(this.form[entry] as string))
    const isTel = !this.formTel.some(entry => !this.getIsValidTel((this.form[entry] as string)))

    return isRequired && isEmail && isTel
  }

  created() {
    this.form.message = this.$t(['form', 'messageDefault'])
  }

  setFormValue(key: string, value: string | boolean) {
    this.form[key] = value
  }

  get selectedTranslated(): {} {
    const selected: IStepsSelected = {}
    Object
      .keys(this.stepsModule.state.selected)
      .forEach((key) => (
        selected[key] = this.stepsModule.state.selected[key]
          .map((selectedKey) => {
            const value = this.$t([key, 'options', selectedKey])

            return value.replace(/<\/?[^>]+(>|$)/g, '')
          })
      ))

    return selected
  }

  async submit() {
    this.isErrorShown = false

    if (this.formIsValid === false) {
      this.isErrorShown = true
      return
    }

    const isSuccess = await this.formModule.send(this.form, this.selectedTranslated)

    if (isSuccess === true) {
      this.stepsModule.next()
      return
    }

    alert('Error')
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";

.contact {
  .input-wrapper {
    @media only screen and (min-width: 960px) {
      margin-top: 60px;
      display: grid;
      gap: $spacing-1 $spacing-1;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        ". ."
        ". .";
      margin-bottom: 20px;
    }
    .input,
    .dropdown-container {
      margin-bottom: 20px;
      @media only screen and (min-width: 960px) {
        margin: 0;
      }
    }
  }
  .button {
    margin: 0 auto;
  }
  .switch {
    margin-bottom: 30px;
  }
  .textarea {
    margin-bottom: 30px;
  }
}
</style>
