import { IStepSelectBranch, IStepSelectElectricity, IStepSelectEmployees, IStepSelectInstallation, IStepSelectMachineType, IStepSelectPayment, IStepSelectProductType, IStepSelectRegion, IStepSelectService, IStepSelectShiftSystem, IStepType } from '@/types';

export const TRANSLATIONS = {
  general: {
    title: 'Erhalten Sie schnell und einfach ein kostenloses Angebot',
    contact: 'Für besonders Eilige geht es hier zum ',
    contactClick: 'Kontaktformular',
    multipleChoice: 'Mehrfachauswahl möglich',
    singleChoice: 'Nur eine Auswahl',
    buttonBack: 'Zurück',
    buttonNext: 'Nächster Schritt',
    buttonNextStep: 'Weiter',
    buttonUnderstand: 'Verstanden',
  },
  form: {
    salutation: 'Anrede',
    salutationMan: 'Herr',
    salutationWoman: 'Frau',
    salutationNone: 'keine Angabe',
    firstName: 'Vorname (Pflichtfeld)',
    lastName: 'Name (Pflichtfeld)',
    firm: 'Firma (Pflichtfeld)',
    email: 'Email-Adresse (Pflichtfeld)',
    tel: 'Telefon (Pflichtfeld)',
    message: 'Ihre Nachricht (Pflichtfeld)',
    messageDefault: 'Bitte beraten Sie mich zu meinem Anliegen.',
    checkbox1: 'Bitte rufen Sie mich an, um die Details abzustimmen. (Pflichtfeld)',
    checkbox2: 'Bitte senden Sie mir den Newsletter mit Angeboten und Neuigkeiten.',
    checkbox3: 'Ich habe die <a href="https://www.klix-kaffeeautomaten.de/datenschutzerklaerung" target="_blank">Datenschutzerklärung</a> gelesen und verstanden. (Pflichtfeld)',
    button: 'Absenden',
  },
  [IStepType.PRODUCT_TYPE]: {
    hero: {
      headline: 'KLIX<sub style="vertical-align: super; font-size: smaller;">®</sub> Getränkeautomaten & mehr:',
      subline: 'Die optimale Lösung für Ihre Betriebsversorgung',
    },
    prefooter: {
      headline: 'Finden Sie die perfekte Versorgungslösung für Ihre Anforderungen!',
    },
    headline: 'Was möchten Sie anbieten?',
    options: {
      [IStepSelectProductType.HOT_DRINK]: 'Heißgetränke',
      [IStepSelectProductType.COLD_DRINK]: 'Kaltgetränke',
      [IStepSelectProductType.SOUP]: 'Suppen',
      [IStepSelectProductType.WATER]: 'Trinkwasser',
      [IStepSelectProductType.SNACK]: 'Snacks',
      [IStepSelectProductType.FRESH]: 'Frischwaren',
    },
  },
  [IStepType.MACHINE_TYPE]: {
    headline: 'Haben Sie bereits einen Getränke- oder Verpflegungsautomaten vor Ort?',
    tooltip: 'Diese Informationen helfen uns die Situation vor Ort besser einzuschätzen und Ihnen ein maßgeschneidertes Angebot zu erstellen. Wir bieten alles aus einer Hand!',
    options: {
      [IStepSelectMachineType.HOT_DRINK]: 'Heißgetränke-automat',
      [IStepSelectMachineType.COLD_DRINK]: 'Kaltgetränkeautomat',
      [IStepSelectMachineType.SNACK]: 'Snack-Automat',
      [IStepSelectMachineType.WATER]: 'andere Automaten',
      [IStepSelectMachineType.CATERING]: 'Kombiautomat für Kaltgetränke & Snacks',
    },
    optionSkip: '<strong>Nein</strong>, noch keinen'
  },
  [IStepType.SERVICE]: {
    headline: 'Welche Verbesserungen wünschen Sie sich gegenüber Ihrer jetzigen Versorgung?',
    tooltip: 'Um unsere Beratung zu optimieren, ist es hilfreich, Ihre aktuellen Herausforderungen zu kennen. So können wir uns im Gespräch auf das Wesentliche konzentrieren und kommen schnell zu einem Ergebnis.',
    options: {
      [IStepSelectService.TECHNICAL_SERVICE]: 'Technischer Service',
      [IStepSelectService.HYGIENE]: 'Hygiene',
      [IStepSelectService.PRODUCT_RANGE]: 'Produktangebot',
      [IStepSelectService.USER_FRIENDLINESS]: 'Nutzerfreundlichkeit',
      [IStepSelectService.PRICE_VALUE]: 'Preis-Leistungs-Verhältnis',
    },
    optionSkip: 'Keine Angabe'
  },
  [IStepType.ELECTRICITY]: {
    headline: 'Ist ein Strom- oder Festwasseranschluss am Aufstellort vorhanden?',
    tooltip: 'Alle Automatentypen benötigen einen Stromanschluss. Die Getränkeautomaten (Heiß-und Kaltgetränke sowie Wasserspender) benötigen zusätzlich in der Regel einen Festwasseranschluss. Sollte dieser aktuell noch nicht vorhanden sein, finden wir gemeinsam eine Lösung!',
    options: {
      [IStepSelectElectricity.ELECTRICITY_AND_WATER]: 'Strom + Wasser',
      [IStepSelectElectricity.ELECTRICITY]: 'Nur Strom',
      [IStepSelectElectricity.WATER]: 'Nur Wasser',
      [IStepSelectElectricity.NONE]: 'Beides nicht',
    },
    overlayTitle: 'Festwasseranschluss notwendig',
    overlayTitle2: 'Stromanschluss notwendig',
    overlayText: 'Liebe Kunden, für die Inbetriebnahme eines KLIX<sub style="vertical-align: super; font-size: smaller;">®</sub> Getränkeautomaten oder Wasserspenders ist ein Festwasseranschluss notwendig. In vielen Fällen lässt sich ein Wasseranschluss in der Nähe des Aufstellungsorts einrichten. Unsere Mitarbeiter beraten Sie gerne, um gemeinsam zu einer Lösung zu kommen.<br><br>Viele Grüße, Ihr KLIX<sub style="vertical-align: super; font-size: smaller;">®</sub> Team',
  },
  [IStepType.BRANCH]: {
    headline: 'In welcher Branche arbeiten Sie?',
    tooltip: 'Unterschiedliche Branchen haben unterschiedliche Anforderungen. Mit unserer mehr als 45-jährigen Expertise haben wir Erfahrungen in jedem Bereich sammeln können. Unsere Kunden haben je nach Umfeld ganz unterschiedliche Anforderungen an eine ideale Getränke- und Automatenversorgung und diese Information hilft uns, Ihren Hintergrund besser zu verstehen.',
    options: {
      [IStepSelectBranch.PRODUCTION_LOGISTICS]: 'Fabrik, Produktion & Logistik',
      [IStepSelectBranch.CATERING]: 'Gastronomie & Catering',
      [IStepSelectBranch.PUBLIC_OR_EDUCATION]: 'Öffentliche Einrichtung oder Bildung',
      [IStepSelectBranch.HEALTH]: 'Gesundheit',
      [IStepSelectBranch.GASTRONOMY]: 'Hotels',
      [IStepSelectBranch.OTHER]: 'Büros, Firmen & Gewerbe',
    },
    overlayTitle: 'Liebe Interessenten,',
    overlayText: 'vielen Dank für Ihr Interesse an unseren Getränkeautomaten. Normalerweise bieten wir Snack- oder Kaltgetränkeautomaten nur in Verbindung mit einem KLIX® Heißgetränkesystem an. In Einzelfällen machen wir jedoch auch Ausnahmen. Bitte teilen Sie uns hierfür Ihre Branche und die Anzahl der Mitarbeiter mit oder passen Sie Ihre Auswahl an.<br><br>Vielen Dank! Ihr KLIX<sub style="vertical-align: super; font-size: smaller;">®</sub> Team',
  },
  [IStepType.EMPLOYEES]: {
    headline: 'Wieviele Mitarbeiterinnen und Mitarbeiter haben Sie?',
    tooltip: 'Unterschiedliche Branchen haben unterschiedliche Anforderungen. Mit unserer mehr als 45-jährigen Expertise haben wir Erfahrungen in jedem Bereich sammeln können. Unsere Kunden haben je nach Umfeld ganz unterschiedliche Anforderungen an eine ideale Getränke- und Automatenversorgung und diese Information hilft uns, Ihren Hintergrund besser zu verstehen.',
    options: {
      [IStepSelectEmployees.SMALL_1]: '1-49',
      [IStepSelectEmployees.SMALL_2]: '50-99',
      [IStepSelectEmployees.SMALL_3]: '100-249',
      [IStepSelectEmployees.SMALL_4]: '250+',
      [IStepSelectEmployees.BIG_1]: '1-29',
      [IStepSelectEmployees.BIG_2]: '30-99',
      [IStepSelectEmployees.BIG_3]: '100-499',
      [IStepSelectEmployees.BIG_4]: '500+',
    },
  },
  [IStepType.SHIFT_SYSTEM]: {
    headline: 'Arbeiten Ihre Mitarbeiterinnen und Mitarbeiter in Schichten?',
    tooltip: 'Diese Information hilft uns, Ihren Bedarf besser einzuschätzen. Wir denken auch an die Kolleginnen und Kollegen, die nachts arbeiten. Oftmals haben die Kantinen und sonstige Verpflegungseinrichtungen zu Nacht- oder Sonderschichten geschlossen. Das KLIX<sub style="vertical-align: super; font-size: smaller;">®</sub> Getränkesystem ist die kleinste Kantine der Welt und hat rund um die Uhr für Sie geöffnet.',
    options: {
      [IStepSelectShiftSystem.NONE]: '<strong>Nein</strong>, wir haben kein Schichtsystem',
      [IStepSelectShiftSystem.SHIFT_2]: '2-Schicht-System',
      [IStepSelectShiftSystem.SHIFT_3]: '3-Schicht-System',
      [IStepSelectShiftSystem.SHIFT_4]: '4-Schicht-System',
    },
  },
  [IStepType.PAYMENT]: {
    headline: 'Welche Zahlungsmöglichkeiten wünschen Sie sich?',
    tooltip: 'Grundsätzlich ist am KLIX<sub style="vertical-align: super; font-size: smaller;">®</sub> Getränkesystem jede Zahlungsweise möglich. Unter Berücksichtigung der Voraussetzungen bei Ihnen vor Ort finden wir für Sie die richtige Lösung.',
    options: {
      [IStepSelectPayment.CARD]: 'Bargeldloses Zahlen',
      [IStepSelectPayment.CASH]: 'Münzzahlung',
      [IStepSelectPayment.OTHER]: 'Anderes Bezahlsystem',
    },
    optionSkip: 'Ich bin mir noch nicht sicher',
  },
  [IStepType.INSTALLATION]: {
    headline: 'Wer soll den Automaten befüllen und sich darum kümmern?',
    tooltip: 'Insbesondere das KLIX<sub style="vertical-align: super; font-size: smaller;">®</sub> Getränkesystem lässt sich aufgrund seiner Einfachheit hervorragend in Eigenregie betreiben, so dass Sie die Bestellung, Befüllung und Reinigung einfach selbst übernehmen können. Wenn Sie sich lieber ein Rundum-Sorglos-Paket wünschen, ist das auch kein Problem. Einer unserer Service-Partner aus unserem deutschlandweiten Netzwerk übernimmt dann für Sie und Sie müssen sich um nichts mehr kümmern.',
    options: {
      [IStepSelectInstallation.SELF]: 'Wir übernehmen das selbst',
      [IStepSelectInstallation.SERVICE]: 'KLIX<sub style="vertical-align: super; font-size: smaller;">®</sub> Rundum-Sorglos-Service',
      [IStepSelectInstallation.UNKNOW]: 'Ich bin mir noch nicht sicher',
    },
  },
  [IStepType.REGION]: {
    headline: 'Wo möchten Sie den Automaten aufstellen?',
    tooltip: 'Diese Information ermöglicht uns, den richtigen Ansprechpartner für Sie zu finden.',
    placeholder: 'Aufstellungsort',
    postcodePlaceholder: 'Postleitzahl',
    options: {
      [IStepSelectRegion.DE]: 'Deutschland',
      [IStepSelectRegion.AUS]: 'Österreich',
      [IStepSelectRegion.CH]: 'Schweiz',
      [IStepSelectRegion.OTHER]: 'Andere Länder',
    },
  },
  [IStepType.OVERVIEW]: {
    headline: 'Alle Ihre Angaben auf einen Blick. Wenn Sie möchten, können Sie diese hier anpassen.',
    labels: {
      [IStepType.PRODUCT_TYPE]: 'Getränke oder Verpflegungsart',
      [IStepType.MACHINE_TYPE]: 'Diese Versorgung haben Sie bereits',
      [IStepType.SERVICE]: 'Diese Verbesserungen wünschen Sie sich',
      [IStepType.ELECTRICITY]: 'Vorhandene Strom- und Festwasseranschlüsse',
      [IStepType.BRANCH]: 'Branche',
      [IStepType.EMPLOYEES]: 'Mitarbeiteranzahl',
      [IStepType.SHIFT_SYSTEM]: 'Schichtsystem',
      [IStepType.PAYMENT]: 'Zahlungssysteme',
      [IStepType.INSTALLATION]: 'Bewirtschaftung',
      [IStepType.REGION]: 'Aufstellungsort',
    },
  },
  [IStepType.CALENDAR]: {
    headline: 'Individuellen Termin vereinbaren– vor Ort, per Video oder Telefon!',
  },
  [IStepType.CONTACT_LONG]: {
    headline: 'Bitte teilen Sie uns Ihre Kontaktdaten mit.',
  },
  [IStepType.CONTACT_SHORT]: {
    headline: 'Gern nehmen wir zur Abstimmung der Details Kontakt mit Ihnen auf.',
  },
  [IStepType.THANK_YOU]: {
    headline: 'Vielen Dank für Ihre Anfrage! Wir melden uns umgehend bei Ihnen.',
    text: 'Gerne möchten wir unsere Website noch nutzerfreundlicher für Sie gestalten und bitten um Ihr Feedback.',
    button: 'Feedback geben',
    buttonLink: 'https://www.klix-kaffeeautomaten.de/termin-vielen-dank/'
  },
}
