import {
  IStep,
  IStepSelectMachineType,
  IStepSelectProductType,
  IStepSelectBranch,
  IStepTemplate,
  IStepType,
  IStepSelectService,
  IStepSelectElectricity,
  IStepSelectEmployees,
  IStepSelectShiftSystem,
  IStepSelectPayment,
  IStepSelectInstallation,
  IStepSelectRegion,
  IStepLayout,
  IStepSelect,
} from '@/types';

export const STEPS: IStep[] = [
  {
    type: IStepType.PRODUCT_TYPE,
    layout: IStepLayout.HOME,
    template: IStepTemplate.TILES,
    isMultiple: true,
    isTooltip: false,
    isSkip: false,
    options: [
      IStepSelectProductType.HOT_DRINK,
      IStepSelectProductType.SNACK,
      IStepSelectProductType.WATER,
      IStepSelectProductType.COLD_DRINK,
      IStepSelectProductType.SOUP,
      IStepSelectProductType.FRESH,
    ],
  },
  {
    type: IStepType.MACHINE_TYPE,
    layout: IStepLayout.STEP_EMPTY,
    template: IStepTemplate.TILES,
    isMultiple: true,
    isTooltip: true,
    isSkip: true,
    options: [
      IStepSelectMachineType.HOT_DRINK,
      IStepSelectMachineType.COLD_DRINK,
      IStepSelectMachineType.SNACK,
      IStepSelectMachineType.CATERING,
      IStepSelectMachineType.WATER,
    ],
  },
  {
    type: IStepType.SERVICE,
    layout: IStepLayout.STEP_EMPTY,
    template: IStepTemplate.TILES,
    isMultiple: true,
    isTooltip: true,
    isSkip: true,
    options: [
      IStepSelectService.USER_FRIENDLINESS,
      IStepSelectService.PRODUCT_RANGE,
      IStepSelectService.TECHNICAL_SERVICE,
      IStepSelectService.HYGIENE,
      IStepSelectService.PRICE_VALUE,
    ],
  },
  {
    type: IStepType.ELECTRICITY,
    layout: IStepLayout.STEP_EMPTY,
    template: IStepTemplate.TILES,
    isMultiple: false,
    isTooltip: true,
    isSkip: false,
    options: [
      IStepSelectElectricity.ELECTRICITY_AND_WATER,
      IStepSelectElectricity.ELECTRICITY,
      IStepSelectElectricity.WATER,
      IStepSelectElectricity.NONE,
    ],
  },
  {
    type: IStepType.BRANCH,
    layout: IStepLayout.STEP_EMPTY,
    template: IStepTemplate.TILES,
    isMultiple: false,
    isTooltip: true,
    isSkip: false,
    options: [
      IStepSelectBranch.PRODUCTION_LOGISTICS,
      IStepSelectBranch.HEALTH,
      IStepSelectBranch.PUBLIC_OR_EDUCATION,
      IStepSelectBranch.GASTRONOMY,
      IStepSelectBranch.CATERING,
      IStepSelectBranch.OTHER,
    ],
  },
  {
    type: IStepType.EMPLOYEES,
    layout: IStepLayout.STEP_EMPTY,
    template: IStepTemplate.TILES,
    isMultiple: false,
    isTooltip: true,
    isSkip: false,
    optionsMapType: [IStepType.BRANCH],
    optionsMap: {
      [IStepSelectBranch.PRODUCTION_LOGISTICS]: [
        IStepSelectEmployees.BIG_1,
        IStepSelectEmployees.BIG_2,
        IStepSelectEmployees.BIG_3,
        IStepSelectEmployees.BIG_4,
      ],
      [IStepSelectBranch.PUBLIC_OR_EDUCATION]: [
        IStepSelectEmployees.SMALL_1,
        IStepSelectEmployees.SMALL_2,
        IStepSelectEmployees.SMALL_3,
        IStepSelectEmployees.SMALL_4,
      ],
      [IStepSelectBranch.HEALTH]: [
        IStepSelectEmployees.SMALL_1,
        IStepSelectEmployees.SMALL_2,
        IStepSelectEmployees.SMALL_3,
        IStepSelectEmployees.SMALL_4,
      ],
      [IStepSelectBranch.GASTRONOMY]: [
        IStepSelectEmployees.SMALL_1,
        IStepSelectEmployees.SMALL_2,
        IStepSelectEmployees.SMALL_3,
        IStepSelectEmployees.SMALL_4,
      ],
      [IStepSelectBranch.CATERING]: [
        IStepSelectEmployees.SMALL_1,
        IStepSelectEmployees.SMALL_2,
        IStepSelectEmployees.SMALL_3,
        IStepSelectEmployees.SMALL_4,
      ],
      [IStepSelectBranch.OTHER]: [
        IStepSelectEmployees.SMALL_1,
        IStepSelectEmployees.SMALL_2,
        IStepSelectEmployees.SMALL_3,
        IStepSelectEmployees.SMALL_4,
      ],
    },
  },
  {
    type: IStepType.SHIFT_SYSTEM,
    layout: IStepLayout.STEP_EMPTY,
    template: IStepTemplate.TILES,
    isMultiple: false,
    isTooltip: true,
    isSkip: false,
    options: [
      IStepSelectShiftSystem.NONE,
      IStepSelectShiftSystem.SHIFT_2,
      IStepSelectShiftSystem.SHIFT_3,
      IStepSelectShiftSystem.SHIFT_4,
    ],
  },
  {
    type: IStepType.PAYMENT,
    layout: IStepLayout.STEP_EMPTY,
    template: IStepTemplate.TILES,
    isMultiple: true,
    isTooltip: true,
    isSkip: true,
    options: [
      IStepSelectPayment.CARD,
      IStepSelectPayment.CASH,
      IStepSelectPayment.OTHER,
    ],
  },
  {
    type: IStepType.INSTALLATION,
    layout: IStepLayout.STEP_EMPTY,
    template: IStepTemplate.TILES,
    isMultiple: false,
    isTooltip: true,
    isSkip: false,
    options: [
      IStepSelectInstallation.SELF,
      IStepSelectInstallation.SERVICE,
      IStepSelectInstallation.UNKNOW,
    ],
  },
  {
    type: IStepType.REGION,
    layout: IStepLayout.STEP_EMPTY,
    template: IStepTemplate.DROPDOWN_SELECT,
    isMultiple: false,
    isTooltip: true,
    isSkip: false,
    options: [
      IStepSelectRegion.DE,
      IStepSelectRegion.AUS,
      IStepSelectRegion.CH,
      IStepSelectRegion.OTHER,
    ],
    optionsMap: {
      [IStepSelectRegion.DE]: []
    },
  },
  {
    type: IStepType.OVERVIEW,
    layout: IStepLayout.STEP_EMPTY,
    template: IStepTemplate.OVERVIEW,
  },
  {
    type: IStepType.CONTACT_LONG,
    layout: IStepLayout.STEP_EMPTY,
    template: IStepTemplate.CONTACT,
  },
  {
    type: IStepType.CONTACT_SHORT,
    layout: IStepLayout.STEP_EMPTY,
    template: IStepTemplate.CONTACT,
  },
  {
    type: IStepType.CALENDAR,
    layout: IStepLayout.STEP_EMPTY,
    template: IStepTemplate.CALENDAR,
  },
  {
    type: IStepType.THANK_YOU,
    layout: IStepLayout.STEP_EMPTY,
    template: IStepTemplate.THANK_YOU,
  },
]

export const STEP_ROUTE_LONG_PRODUCT_TYPE = [
  IStepSelectProductType.HOT_DRINK,
]

export const STEP_ROUTE_SHORT_STEPS = [
  IStepType.PRODUCT_TYPE,
  IStepType.BRANCH,
  IStepType.EMPLOYEES,
  IStepType.CONTACT_SHORT,
  IStepType.THANK_YOU,
]

export const STEP_ROUTE_LONG_STEPS = [
  IStepType.PRODUCT_TYPE,
  IStepType.MACHINE_TYPE,
  IStepType.SERVICE,
  IStepType.ELECTRICITY,
  IStepType.BRANCH,
  IStepType.EMPLOYEES,
  IStepType.SHIFT_SYSTEM,
  IStepType.PAYMENT,
  IStepType.INSTALLATION,
  IStepType.REGION,
  IStepType.OVERVIEW,
  IStepType.CONTACT_LONG,
  // IStepType.CALENDAR,
  IStepType.THANK_YOU
]

export const STEP_ROUTE_LONG_STEPS_MACHINE_SKIP = [
  IStepType.PRODUCT_TYPE,
  IStepType.MACHINE_TYPE,
  IStepType.ELECTRICITY,
  IStepType.BRANCH,
  IStepType.EMPLOYEES,
  IStepType.SHIFT_SYSTEM,
  IStepType.PAYMENT,
  IStepType.INSTALLATION,
  IStepType.REGION,
  IStepType.OVERVIEW,
  IStepType.CONTACT_LONG,
  // IStepType.CALENDAR,
  IStepType.THANK_YOU
]

export const STEP_ROUTE_STEPS_BRANCH = [
  IStepType.BRANCH,
  IStepType.EMPLOYEES,
  IStepType.SHIFT_SYSTEM,
  IStepType.PRODUCT_TYPE,
  IStepType.SERVICE,
  IStepType.ELECTRICITY,
  IStepType.PAYMENT,
  IStepType.INSTALLATION,
  IStepType.REGION,
  IStepType.OVERVIEW,
  IStepType.CONTACT_LONG,
  // IStepType.CALENDAR,
  IStepType.THANK_YOU
]

export const STEP_SELECT_OPTION_NOT_INSTANT: IStepSelect[] = [
  IStepSelectElectricity.NONE,
  IStepSelectElectricity.ELECTRICITY,
  IStepSelectElectricity.WATER,
]

export const STEP_TILES_LABEL: IStepSelect[] = [
  IStepSelectInstallation.SELF,
]
