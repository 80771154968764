<template>
  <div class="thank-you">
  </div>
</template>

<script lang="ts">
import { StepsModule } from '@/composables/use-steps';
import { TranslationHandler } from '@/mixins/translation-handler';
import { Options, Vue, prop, mixins } from 'vue-class-component';

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })
}

@Options({
  components: {
  },
})
export default class ThankYou extends mixins(TranslationHandler, Vue.props(Props)) {
  mounted() {
    window.open(this.$t([this.stepsModule.state.stepCurrent.type, 'buttonLink']), '_self')
  }
}
</script>

<style lang="scss" scoped>

</style>
